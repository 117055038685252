import React from 'react'
// import { NavLink } from 'react-router-dom'

import {
  ThemeProvider,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles'
import { StyledEngineProvider, Container, Grid, Typography, Button } from '@material-ui/core'

import Icon from '@mdi/react'
import { mdiMail, mdiPhone, mdiMap } from '@mdi/js'

import ContactForm from './forms/ContactForm'
import SocialIcons from './SocialIcons'

import { MainTheme as theme } from '../themes/MainTheme'

import SiteLogo from './assets/SiteLogo'

const darkTheme = createMuiTheme({
  ...theme,
  palette: {
    mode: 'dark',
    primary: { main: theme.palette.primary.main },
    secondary: { main: theme.palette.secondary.main },
  },
})

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(12, 8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 3, 0),
    },
    backgroundColor: '#181818',
    color: 'white',
  },
  footerLeft: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      textAlign: 'center',
    },
  },
  footerRight: {},
  siteLogo: {
    width: theme.spacing(20),
    marginRight: theme.spacing(2),
    verticalAlign: 'top',
    fill: theme.palette.common.white,
  },
  siteLogoName: {
    // display: 'inline-block',
    // lineHeight: `${theme.spacing(12)}`,
  },
  contactText: {
    paddingLeft: theme.spacing(2),
    // color: theme.palette.tertiary.main,
    color: theme.palette.common.white,
    lineHeight: `${theme.spacing(3)}`,
    fontWeight: 300,
    // [theme.breakpoints.only('xs')]: {
    //   padding: theme.spacing(0.75, 2),
    // },
  },
  contactIcon: {
    // width: `${theme.spacing(3)} !important`,
    // height: `${theme.spacing(3)} !important`,
    // marginLeft: theme.spacing(-4),
    // marginRight: theme.spacing(3),
    verticalAlign: 'middle',
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  copyright: {
    margin: theme.spacing(12, -8, 0),
    padding: theme.spacing(10, 0),
    backgroundColor: '#121212',
    color: theme.palette.grey[400],
    textAlign: 'center',

    '& a': {
      verticalAlign: 'baseline',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(12, -3, 0),
    },
  },
  designedBy: {
    '&, &>a': {
      fontWeight: 400,
      color: theme.palette.grey[800],
      textTransform: 'none',
      verticalAlign: 'baseline',
    },
  },
}))

export default (function Footer(props) {
  const classes = useStyles()
  const { config } = props
  const currentYear = new Date().getFullYear()

  function createMarkup(content) {
    return { __html: content }
  }

  return (
    <footer className={classes.root}>
      <ThemeProvider theme={darkTheme}>
        <StyledEngineProvider injectFirst>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={5} className={classes.footerLeft}>
                <Grid container spacing={3}>
                  <Grid item xs={12} align="center">
                    <SiteLogo
                      siteLogo={config.siteLogo}
                      siteLogoType={config.siteLogoType}
                      siteName={config.siteTitle}
                      siteLogoPosition="Footer"
                    />
                    {JSON.parse(config.showSiteNameFooter) && (
                      <Typography variant="h6" className={classes.siteLogoName}>
                        {config.siteTitle}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                  >
                    <Grid container spacing={2}>
                      {config.siteContactEmail && (
                        <Grid item xs={12}>
                          <Button
                            variant="text"
                            startIcon={
                              <Icon
                                path={mdiMail}
                                size={1}
                                color="white"
                                className={classes.contactIcon}
                              />
                            }
                            className={classes.contactText}
                            // href={`tel:${config.siteContactPhone}`}
                            target="_blank"
                            style={{ textTransform: 'none', cursor: 'default' }}
                          >
                            {config.siteContactEmail}
                          </Button>
                        </Grid>
                      )}
                      {config.siteContactPhone && (
                        <Grid item xs={12}>
                          <Button
                            variant="text"
                            startIcon={
                              <Icon
                                path={mdiPhone}
                                size={1}
                                color="white"
                                className={classes.contactIcon}
                              />
                            }
                            className={classes.contactText}
                            href={`tel:${config.siteContactPhone}`}
                            target="_blank"
                            style={{ textTransform: 'none' }}
                          >
                            {config.siteContactPhone}
                          </Button>
                        </Grid>
                      )}
                      {config.siteContactAddress && (
                        <Grid item xs={12}>
                          <Button
                            variant="text"
                            startIcon={
                              <Icon
                                path={mdiMap}
                                size={1}
                                color="white"
                                className={classes.contactIcon}
                              />
                            }
                            className={classes.contactText}
                            href={config.siteContactMapLink}
                            target="_blank"
                            style={{ textTransform: 'none' }}
                          >
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                config.siteContactAddress,
                              )}
                            />
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={false} md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={5} className={classes.footerRight}>
                <ContactForm />
              </Grid>
            </Grid>
          </Container>
          <div className={classes.copyright}>
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SocialIcons
                    size=""
                    showIcons={[
                      {
                        name: 'Facebook',
                        url: 'https://www.facebook.com/mscitech/',
                      },
                      // {
                      //   name: 'Instagram',
                      //   url: 'https://www.instagram.com/',
                      // },
                      // { name: 'Pinterest', url: 'https://pinterest.com' },
                      // { name: 'Twitter', url: 'https://twitter.com' },
                      // { name: 'Behance', url: 'https://behance.net' },
                      // { name: 'Dribbble', url: 'https://dribbble.com' },
                    ]}
                    // square
                  />
                  <Typography variant="caption" component="div">
                    <span>Copyright &copy; {currentYear} </span>
                    <Button
                      size="small"
                      color="inherit"
                      variant="text"
                      href={config.siteCopyrightOwnerURL}
                      target="_blank"
                    >
                      {config.siteCopyrightOwner}
                    </Button>
                    <br />
                    <span>Toate drepturile rezervate.</span>
                  </Typography>
                  {/* <Button
                    size="small"
                    color="inherit"
                    variant="text"
                    to="/ro/politica-de-confidentialitate"
                    component={NavLink}>
                    Politica de confidențialitate
                  </Button> */}
                </Grid>
              </Grid>
              <br />
              <Typography
                variant="caption"
                component="div"
                className={classes.designedBy}
              >
                <Button
                  disableElevation
                  disableRipple
                  variant="text"
                  size="small"
                  href="https://edgencia.ro"
                  target="_blank"
                >
                  designed and developed by edgencia
                </Button>
              </Typography>
            </Container>
          </div>
        </StyledEngineProvider>
      </ThemeProvider>
    </footer>
  )
})
